<template>
    <v-row no-gutters>
        <v-col cols="12">
            <ProductBar :organizationId="$route.params.organizationId" :product="product" class="mb-6" v-if="product"></ProductBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link> &gt;
                    <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">Products</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="product">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Product {{ product.id }}</p>
                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Product
                                <span v-if="product.is_draft_pending && !product.published">(draft)</span>
                                <span v-if="product.is_draft_pending && product.published">(published)</span>
                                <v-chip v-if="product.is_active" label small class="px-1 ml-2 green darken-2 white--text">Active</v-chip>
                                <v-chip v-if="!product.is_active" label small class="px-1 ml-2 green darken-2 black--text">Inactive</v-chip>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn text small class="px-1 ml-2 green darken-2 white--text" @click="publishProduct" v-if="product.is_draft_pending && product.is_active">Update</v-btn>
                            <v-btn text small class="px-1 ml-2 green darken-2 white--text" @click="publishProduct" v-if="product.is_draft_pending && !product.is_active">Publish</v-btn>
                            <v-btn icon color="white" @click="publishProduct" v-if="product.is_draft_pending">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-caption mb-0 pb-0 mt-0">The following product information is PUBLIC and is shown to customers.</p>
                        </v-card-text>
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Name</th>
                                        <td style="width: 100%;">
                                            <v-row no-gutters align="center">
                                                <EditableText :value="product.name" @input="saveProductName" dense/>
                                                <ProductLink :organization="organization" :product="product" by="id" target="_blank">Link with ID</ProductLink>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Reference</th>
                                        <td>
                                            <v-row no-gutters align="center">
                                                <EditableText :value="product.ref" @input="saveProductRef" dense/>
                                                <ProductLink :organization="organization" :product="product" by="ref" target="_blank" v-if="product.ref">Link with ref</ProductLink>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Alias</th>
                                        <td>
                                            <v-row no-gutters align="center">
                                                <EditableText :value="product.alias" @input="saveProductAlias" dense/>
                                                <ProductLink :organization="organization" :product="product" by="alias" target="_blank" v-if="product.alias">Link with alias</ProductLink>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>
                                            <EditableTextSelect :value="product.type" :items="productTypeChoices" @input="saveProductType" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Description</th>
                                        <td>
                                            <EditableText :value="product.description" @input="saveProductDescription" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Product&nbsp;Group</th>
                                        <td>
                                            <EditableTextSelect :value="product.product_group_id" :items="productGroupChoices" @input="saveProductGroup" @edit-mode="loadProductGroupList" dense>
                                                <template #after-button v-if="product.product_group_id">
                                                    <router-link :to="{ name: 'organization-edit-product-group', params: { organizationId: $route.params.organizationId }, query: { id: product.product_group_id, t: Date.now() } }">Edit</router-link>
                                                </template>
                                            </EditableTextSelect>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <!-- TODO: there should be a constants file so we can have PRODUCT_TYPE_SAAS here so it's clearly a constant that has to match the server's constant, and not an arbitrary UI value -->
                        <template v-if="product.type === 'saas'">
                            <EditableProductSaasContent :productId="product.id" :value="product.content.saas" @input="saveProductContentByType"></EditableProductSaasContent>
                            <!-- <p class="text-overline mb-0 mt-8">Service</p>
                            <p class="mb-0 pb-0 mt-0 text-caption">
                                This service will be linked to the account when the product order is fulfilled.
                            </p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelect :value="product.content.service_id" :items="serviceChoices" @input="saveProductServiceId" dense/>
                            </p> -->
                        </template>

                        <template v-if="product.type === 'download'">
                            <EditableProductDownloadContent :productId="product.id" :value="product.content.download" @input="saveProductContentByType"></EditableProductDownloadContent>
                        </template>

                            <!-- TODO: enter flat rate shipping (when using USPS) or set up custom rate shipping integration for FedEx, UPS, etc. based on package size and weight and the carrier... various services exist that help with the computation, or we can do our own query of the current rates and compute for the merchant... -->

                            <!-- TODO: move to comment component -->
                            <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The comment is PRIVATE and is NOT shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="product.comment" @input="saveProductComment" dense/>
                            </p> -->
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewBrandProfileURL" target="_blank">{{ viewBrandProfileURL }}</TextLink>
                            </p> -->

                        <v-card-text>
                            <p class="text-overline mb-0 mt-0">
                                Pricing
                                <v-btn icon color="green darken-2" @click="createPrice">
                                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                </v-btn>
                            </p>
                            <v-list v-if="Array.isArray(productPriceList)">
                                <v-list-item v-for="item in productPriceList" :key="item.id">
                                    <v-list-item-content>
                                        <EditableProductPrice :value="item" :product="product" @input="saveProductPrice" @delete="deleteProductPrice"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- <v-card flat tile color="white">
                                <v-toolbar dense flat color="white">
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="green darken-2">
                                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-card> -->
                        </v-card-text>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-0">Checkout Requirements</p>
                        </v-card-text>
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td style="width: 100%;">
                                            <EditableTextSelect :value="product.checkout.require_name" :items="yesnoChoices" @input="saveProductRequireName" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>
                                            <EditableTextSelect :value="product.checkout.require_email" :items="yesnoChoices" @input="saveProductRequireEmail" dense/>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <th>Phone</th>
                                        <td>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <th>Signup</th>
                                        <td>
                                            <EditableTextSelect :value="product.checkout.require_signup" :items="yesnoChoices" @input="saveProductRequireSignup" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Billing&nbsp;address</th>
                                        <td>
                                            <EditableTextSelect :value="product.checkout.require_billing" :items="yesnoChoices" @input="saveProductRequireBilling" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Shipping&nbsp;address</th>
                                        <td>
                                            <EditableTextSelect :value="product.checkout.require_shipping" :items="yesnoChoices" @input="saveProductRequireShipping" dense/>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                    <!-- <v-dialog v-model="addPriceDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="green lighten-3">
                            <v-toolbar-title >Add Price</v-toolbar-title>
                        </v-toolbar>
                        <v-form @submit.prevent="createPrice" @keyup.enter.native.prevent="createPrice" class="px-2">
                            <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newItemLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createPrice" :disabled="!isCreatePriceFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="addPriceDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
import EditableTextSelect from '@/components/EditableTextSelect.vue';
import EditableProductPrice from '@/components/EditableProductPrice.vue';
import ProductBar from '@/components/ProductBar.vue';
import ProductLink from '@/components/ProductLink.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import EditableProductDownloadContent from '@/components/EditableProductDownloadContent.vue';
import EditableProductSaasContent from '@/components/EditableProductSaasContent.vue';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
        EditableText,
        EditableTextSelect,
        EditableProductPrice,
        ProductBar,
        ProductLink,
        AccessDeniedOverlay,
        EditableProductDownloadContent,
        EditableProductSaasContent,
    },
    data: () => ({
        // organization: null,
        product: null,
        productPriceList: null,
        productGroupChoices: null,
        productGroup: null,
        serviceList: null,
        status: null,
        error: null,
        forbiddenError: false,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        addPriceDialog: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            organization: (state) => state.organization,
            productTypeChoices: (state) => state.productTypeChoices,
            productPublishedChoices: (state) => state.productPublishedChoices,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreatePriceFormComplete() {
            return false;
        },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        serviceChoices() {
            if (!Array.isArray(this.serviceList)) {
                return [];
            }
            return this.serviceList.map((item) => ({
                text: item.name,
                value: item.id,
            }));
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableBrandProfileInput'); }, 1);
                });
            }
        },
        $route(newValue, oldValue) {
            if (newValue.query.id !== oldValue.query.id) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.loadProduct();
                        this.loadProductPriceList(); // TODO: actually we should get the price list from the draft product's content area, and only make changes to product_price table when we publish; then product_price table can be for only published prices and doesnt need the published preset
                    }, 1);
                });
            }
        },
    },
    methods: {
        // async loadOrganization() {
        //     try {
        //         this.error = false;
        //         await this.$store.dispatch('loadOrganization', { organizationId: this.$route.params.organizationId });
        //         // this.$store.commit('loading', { loadOrganization: true });
        //         // const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
        //         // console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
        //         // if (response) {
        //         //     this.organization = response;
        //         // } else {
        //         //     // TODO: redirect back to organization list? show a not found message?
        //         // }
        //     } catch (err) {
        //         console.error('failed to load organization', err);
        //         // TODO: move this forbidden error check to App.vue where we load the organization data
        //         if (err.response?.status === 403) {
        //             this.forbiddenError = true;
        //         }
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { loadOrganization: false });
        //     }
        // },
        async loadProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProduct: true });
                const query = { id: this.$route.query.id };
                if (this.$route.query.published) {
                    query.published = this.$route.query.published; // 'true' or 'false'
                }
                const response = await this.$client.organization(this.$route.params.organizationId).product.get(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.product = response;
                    if (this.product.product_group_id) {
                        this.loadProductGroup();
                    } else {
                        this.productGroup = null;
                    }
                    this.checkProduct();
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load product', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProduct: false });
            }
        },
        async loadProductGroup() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductGroup: true });
                const query = { id: this.product.product_group_id };
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.get(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response?.item) {
                    this.productGroup = response.item;
                    this.productGroupChoices = [
                        { value: this.productGroup.id, text: this.productGroup.label },
                    ];
                }
            } catch (err) {
                console.error('failed to load product group', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductGroup: false });
            }
        },
        async loadProductGroupList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductGroupList: true });
                const query = { type: this.product.type };
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.search(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.productGroupList = response.list;
                    this.productGroupChoices = response.list.map((productGroup) => ({
                        value: productGroup.id,
                        text: productGroup.label,
                    }));
                }
            } catch (err) {
                console.error('failed to load product group list', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductGroupList: false });
            }
        },
        async checkProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkProduct: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).product.check(query, { item: 'stripe' });
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.status = response;
                } else {
                    this.status = null;
                }
            } catch (err) {
                console.error('failed to check product status', err);
                this.status = null;
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkProduct: false });
            }
        },
        async loadProductPriceList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductPriceList: true });
                const query = { product_id: this.$route.query.id };
                if (this.$route.query.published === 'true') {
                    query.published = 'true'; // this.$route.query.published; // 'true' or 'false'
                } else {
                    query.published = 'false';
                }
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.search(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.productPriceList = response.list;
                } else {
                    this.productPriceList = [];
                }
                // check if any of the prices have a draft pending, and enable the publish button for that
                const isPriceDraftPending = this.productPriceList.reduce((acc, cur) => acc || cur, false);
                if (isPriceDraftPending) {
                    this.$set(this.product, 'is_draft_pending', true);
                }
            } catch (err) {
                console.error('failed to load prices', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductPriceList: false });
            }
        },
        async loadServiceList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadServiceList: true });
                // const query = { product_id: this.$route.query.id };
                const query = {}; // TODO: look up services matching this product type? so membership product -> membership service list??
                const response = await this.$client.organization(this.$route.params.organizationId).accountService.search(query);
                console.log(`loadServiceList response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.serviceList = response.list;
                } else {
                    this.serviceList = [];
                }
            } catch (err) {
                console.error('failed to load service list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadServiceList: false });
            }
        },
        async editBrandProfileAlias() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editBrandProfileAlias: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'brandprofile' }, { content: this.editableBrandProfileAlias });
                console.log(`editBrandProfileAlias: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditBrandProfile = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadSetting();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
                }
            } catch (err) {
                console.error('failed to edit BrandProfile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
            } finally {
                this.$store.commit('loading', { editBrandProfileAlias: false });
            }
        },
        async saveProductAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.product, name, value);
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit product' });
                }
            } catch (err) {
                console.error(`failed to edit product attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit product' });
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveProductName(name) {
            this.saveProductAttr('name', name);
        },
        async saveProductRef(ref) {
            this.saveProductAttr('ref', ref);
        },
        async saveProductAlias(alias) {
            this.saveProductAttr('alias', alias);
        },
        async saveProductType(type) {
            await this.saveProductAttr('type', type);
            await this.loadProductPriceList(); // server may have changed some prices to draft if they don't apply to the new product type
        },
        async saveProductGroup(productGroupId) {
            await this.saveProductAttr('product_group_id', productGroupId);
            if (Array.isArray(this.productGroupList)) {
                this.productGroup = this.productGroupList.find((productGroup) => productGroup.id === productGroupId);
            } else {
                this.loadProductGroup(); // will use the new value of this.product.product_group_id
            }
        },
        async saveProductRequireName(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_name = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireEmail(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_email = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireSignup(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_signup = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireBilling(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_billing = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductRequireShipping(value) {
            const checkout = this.product.checkout ?? {};
            checkout.require_shipping = value;
            this.saveProductAttr('checkout', checkout);
        },
        async saveProductDescription(description) {
            this.saveProductAttr('description', description);
        },
        /**
         * The type-specific content for a product is stored in the product
         * record as content[type], so that if the user switch the type of an
         * object and then decides to switch it back, the type-specific
         * information is not lost, while also not being mixed up with the
         * other type-specific information. The server may decide to remove
         * all irrelevant (not for the current type) type-specific information
         * when the draft is published.
         */
        async saveProductContentByType(value) {
            const content = this.product.content ?? {};
            const type = this.product.type; // must not be null
            this.saveProductAttr('content', { ...content, [type]: value });
        },
        // TODO: redo this with new comment API and comment collection by ADDING a comment or EDITING an existing comment or DELETING an existing comment ; but these need to be done just once because comments can apply to any object; so we need a comment component that will show up on this view and user cna tap it to manage comments
        // async saveProductComment(comment) {
        //     this.saveProductAttr('comment', comment);
        // },
        async publishProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishProduct: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.publish({ id: this.$route.query.id });
                console.log(`publishProduct: response ${JSON.stringify(response)}`);
                if (typeof response?.status?.isPublished === 'boolean') {
                    this.$set(this.product, 'is_active', response.status.isPublished);
                    if (response.status.isPublished) {
                        this.$set(this.product, 'is_draft_pending', false);
                        for (let i = 0; i < this.productPriceList.length; i += 1) {
                            const item = this.productPriceList[i];
                            item.is_active = response.status.isPublished;
                            item.is_draft_pending = false;
                            this.productPriceList.splice(i, 1, item);
                        }
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish product' });
                }
            } catch (err) {
                console.error('failed to publish product', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish product' });
            } finally {
                this.$store.commit('loading', { publishProduct: false });
            }
        },
        async unpublishProduct() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishProduct: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.unpublish({ id: this.$route.query.id });
                console.log(`publishProduct: response ${JSON.stringify(response)}`);
                if (typeof response?.status?.isPublished === 'boolean') {
                    this.$set(this.product, 'is_active', response.status.isPublished);
                    if (!response.status.isPublished) {
                        this.$set(this.product, 'is_draft_pending', true);
                    }
                    for (let i = 0; i < this.productPriceList.length; i += 1) {
                        const item = this.productPriceList[i];
                        item.is_active = false;
                        item.is_draft_pending = true;
                        this.productPriceList.splice(i, 1, item);
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish product' });
                }
            } catch (err) {
                console.error('failed to unpublish product', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish product' });
            } finally {
                this.$store.commit('loading', { publishProduct: false });
            }
        },
        async createPrice() {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.create({
                    product_id: this.$route.query.id,
                });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.productPriceList.push(editedItem);
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create product price' });
                }
            } catch (err) {
                console.error('failed to edit product price', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create product price' });
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveProductPrice(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductPrice: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.edit({ id: item.id }, item);
                console.log(`saveProductPrice: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    const editedItem = { ...item, is_draft_pending: true };
                    const idx = this.productPriceList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.productPriceList.splice(idx, 1, editedItem);
                    } else {
                        this.productPriceList.push(editedItem);
                    }
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save product price' });
                }
            } catch (err) {
                console.error('failed to save product price', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save product price' });
            } finally {
                this.$store.commit('loading', { saveProductPrice: false });
            }
        },
        async deleteProductPrice(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { deleteProductPrice: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productPrice.delete({ id: item.id });
                console.log(`deleteProductPrice: response ${JSON.stringify(response)}`);
                if (response?.isDeleted) {
                    const idx = this.productPriceList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.productPriceList.splice(idx, 1);
                    }
                    this.$set(this.product, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete product price' });
                }
            } catch (err) {
                console.error('failed to delete product price', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete product price' });
            } finally {
                this.$store.commit('loading', { deleteProductPrice: false });
            }
        },
    },
    mounted() {
        // this.loadOrganization();
        this.loadProduct();
        this.loadProductPriceList();
        this.loadServiceList();
    },
};
</script>
